import React from 'react';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';

const NotFoundPage = () => {
  return (
    <main>
      <Typography variant="h1">404: Not Found</Typography>
      <Typography>
        You just hit a route that doesn&#39;t exist... the sadness.
      </Typography>
    </main>
  );
};

export default NotFoundPage;
